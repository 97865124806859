<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>

    <v-card elevation="0">
      <v-card-title> <b>Administrar Ponto de Ônibus</b> </v-card-title>
      <v-card-subtitle>
        Aqui você pode visualizar, adicionar ou editar os pontos de ônibus.
      </v-card-subtitle>
      <v-card-actions class="justify-end action-buttons">
        <GenericButton
          class="mr-4"
          title="Cancelar"
          icon="mdi-close"
          secondary
          @click="cancelEdit"
        ></GenericButton>
        <template v-if="loading">
          <div class="text-center">
            <v-progress-circular
              style="width: 99px"
              class="pa-3"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </template>
        <GenericButton
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          @click="save"
          :disabled="!isValid"
          v-if="!loading"
        ></GenericButton>
      </v-card-actions>
    </v-card>

    <v-card outlined elevation="0" class="pa-6 ma-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-select
              label="Complexo"
              v-model="busStop.regionId"
              :items="regions"
              item-text="name"
              item-value="id"
              outlined
              dense
              :rules="[isNotEmptyRule]"
              @input="fieldsHasChangedAndValid"
            />
          </v-col>
          <v-col class="pb-0">
            <gmap-autocomplete
              v-bind:class="{ autoCompleteIsEmpty: autoCompleteIsEmpty }"
              class="introInput mb-4"
              ref="gmapAutocomplete"
              @input="updateAddress"
              @place_changed="updateAddress"
            >
              <template v-slot:input="slotProps">
                <v-text-field
                  outlined
                  label="Endereço"
                  placeholder="Pesquisar..."
                  color="secondary"
                  v-on:listeners="slotProps.listeners"
                  v-on:attrs="slotProps.attrs"
                ></v-text-field>
              </template>
            </gmap-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              label="Referência"
              outlined
              dense
              v-model="busStop.reference"
              @input="fieldsHasChangedAndValid"
            ></v-text-field>
          </v-col>
          <v-col class="pb-0">
            <v-text-field
              label="Nome"
              outlined
              dense
              v-model="busStop.name"
              :rules="[isNotEmptyRule]"
              @input="fieldsHasChangedAndValid"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              label="Latitude"
              outlined
              dense
              v-model="busStop.latitude"
              :disabled="true"
            ></v-text-field>
          </v-col>
          <v-col class="pb-0">
            <v-text-field
              label="Longitude"
              outlined
              dense
              v-model="busStop.longitude"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12" class="mt-4 pt-0">
          <GmapMap
            ref="mapRef"
            :center="currentLocation"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%; height: 46vh"
            @click="handleMapClick"
          >
            <GmapMarker
              :position="marker.position"
              :clickable="true"
              :draggable="true"
              :visible="visibleMarker"
              @click="center = marker.position"
              @dragend="handleMarkerDrag"
            />
          </GmapMap>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="showCancelConfirm" width="500">
      <v-card>
        <v-card-title> Cancelar </v-card-title>
        <v-card-text> Tem certeza de que gostaria de cancelar ? </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <GenericButton class="mr-4" title="Não" secondary @click="showCancelConfirm = false"></GenericButton>
          <GenericButton title="Sim" primary @click="cancelConfirm"></GenericButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { RegionService } from "@/services/region";
import { isNotEmptyRule } from "@/services/validation";
import { BusStopFormStore } from "@/store/busStopForm";
import { BusStopsService } from "@/services/busStops";
import CustomAlert from "@/components/CustomAlert";
import { gmapApi } from "vue2-google-maps";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "BusStopForm",
  store: BusStopFormStore,
  components: {
    CustomAlert,
    GenericButton,
  },

  computed: {
    google: gmapApi,
    busStopAddress: {
      get: function () {
        return this.busStop.address == null ? {} : this.busStop.address;
      },
    },
  },

  data: () => ({
    loading: false,
    busStop: {
      id: "",
      name: "",
      address: "",
      reference: "",
      latitude: "",
      longitude: "",
      regionId: "",
    },
    marker: {
      position: {
        lat: -15.7801,
        lng: -47.9292,
      },
    },
    chosenAddress: {
      id: undefined,
      name: "",
      formattedAddress: "",
      givenAddress: "",
      postalCode: "",
      complement: "",
      latitude: 0,
      longitude: 0,
    },
    autoCompleteIsEmpty: false,
    currentLocation: {
      lat: -15.7801,
      lng: -47.9292,
    },
    address: "",
    regions: [],
    editing: false,
    isValid: false,
    updating: false,
    showAlert: false,
    alertMessageType: "default",
    alertType: "success",
    customCoordinates: null,
    customMarkers: [],
    addresses: [],
    addressSearch: "",
    busStopBackup: {},
    showCancelConfirm: false,
    valid: false,
    zoom: 16,
    visibleMarker: true,
  }),
  methods: {
    isNotEmptyRule,
    deleteError() {
      this.setAlertProps(false, "deleteError");
    },
    cancelEdit() {
      this.showCancelConfirm = true;
    },
    cancelConfirm() {
      this.showCancelConfirm = false;
      this.$router.push("bus-stops-management");
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.loading = true;
        this.updating = true;
        if (this.busStop.id) {
          await BusStopsService.updateBusStop(this.busStop);
        } else {
          await BusStopsService.addBusStop(this.busStop);
        }
        this.updating = false;
        this.editing = false;
        this.alertType = "success";
        this.showAlert = true;
        this.alertMessageType = "defaultSuccess";
        this.loading = false;
        this.$router.push({
          path: "bus-stops-management",
          query: { showAlert: true },
        });
      } catch (e) {
        this.alertType = "error";
        this.alertMessageType = "defaultError";
        this.showAlert = true;
        this.loading = false;
      }
    },
    closeAlert() {
      this.showAlert = false;
    },

    convertCommaToPoint(value) {
      return value.replace(/,/g, ".");
    },

    inputIsValid() {
      if (
        this.busStop.regionId !== undefined &&
        this.busStop.regionId !== "" &&
        this.busStop.address !== null &&
        this.busStop.address !== undefined &&
        this.busStop.address !== "" &&
        this.busStop.name !== undefined &&
        this.busStop.name !== ""
      ) {
        return true;
      }
      return false;
    },

    hasChange() {
      if (
        (!Object.is(this.busStopBackup.regionId, this.busStop.regionId) ||
          !Object.is(this.busStopBackup.address, this.busStop.address) ||
          !Object.is(this.busStopBackup.reference, this.busStop.reference) ||
          !Object.is(this.busStopBackup.name, this.busStop.name)) &&
        this.inputIsValid()
      ) {
        return true;
      }
      return false;
    },

    fieldsHasChangedAndValid() {
      if (
        this.busStopBackup.reference === null ||
        this.busStopBackup.reference === undefined
      ) {
        this.busStopBackup.reference = "";
      }

      if (this.editMode) {
        if (this.hasChange()) {
          this.isValid = true;
          return;
        }
      } else if (this.inputIsValid()) {
        this.isValid = true;
        return;
      }
      this.isValid = false;
    },

    async handleMapClick(e) {
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.marker.position = position;
      this.updateMarkerPosition(position);
      await this.setChosenAddress(position);
      this.fieldsHasChangedAndValid();
      this.autoCompleteIsEmpty = false;
    },

    async handleMarkerDrag(event) {
      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.marker.position = position;
      this.updateMarkerPosition(position);
      await this.setChosenAddress(position);
      this.fieldsHasChangedAndValid();
      this.autoCompleteIsEmpty = false;
    },

    updateMarkerPosition(position) {
      this.marker.position = position;
    },

    setAddressInput(address) {
      this.$refs.gmapAutocomplete.$refs.input.value = address;
    },

    async setChosenAddress(position) {
      this.busStop.latitude = position.lat;
      this.busStop.longitude = position.lng;
      const geocode = await this.getGeocode(position);
      this.chosenAddress.formattedAddress = geocode[0].formatted_address;
      this.setAddressInput(this.chosenAddress.formattedAddress);
      this.busStop.address = this.$refs.gmapAutocomplete.$refs.input.value;
    },

    getMapRef() {
      const mapRef = this.$refs["mapRef"];
      if (!mapRef) {
        throw new Error("vue2-google-maps mapRef not found on Vue $refs");
      }
      return mapRef;
    },

    async getMap() {
      return await this.getMapRef().$mapPromise;
    },

    panToMarker() {
      this.getMapRef().panTo(this.marker.position);
    },

    async updateAddress(place) {
      if (place.place_id) {
        const service = new this.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId: place.place_id }, (result, status) => {
          if (status === this.google.maps.places.PlacesServiceStatus.OK) {
            const position = {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            };

            this.marker.position = position;
            this.busStop.latitude = position.lat;
            this.busStop.longitude = position.lng;

            this.busStop.address = result.formatted_address;
            this.setAddressInput(result.formatted_address);
            console.log(this.busStop)
            
            this.panToMarker(); 
            this.visibleMarker = true;
            this.fieldsHasChangedAndValid();
          }
        });
      } else {
        this.busStop.address = "";
        this.busStop.latitude = "";
        this.busStop.longitude = "";
        this.fieldsHasChangedAndValid();
      }
    },

    geolocate() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.marker.position.lat = this.currentLocation.lat;
            this.marker.position.lng = this.currentLocation.lng;
            this.visibleMarker = true;
            this.zoom = 16;
            resolve(this.currentLocation);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    getGeocode(position) {
      const geocoder = new this.google.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode(
          {
            location: {
              lat: parseFloat(position.lat),
              lng: parseFloat(position.lng),
            },
          },
          (results_, status_) => {
            if (status_ !== this.google.maps.GeocoderStatus.OK) {
              const reason = `geocode: status: ${status_}`;
              return reject(reason);
            } else {
              this.visibleMarker = true;
              this.zoom = 16;
            }
            return resolve(results_);
          }
        );
      });
    },

    /**
     * Finds the postal code for a location.
     * @param geocode - The geocode result object returned from the Google Maps APIs.
     * @return {null|string} - The postal code of the location corresponding to the
     *  geocode result object, if it exists, and null otherwise.
     */
    findPostalCodeInGeocode(geocode) {
      if (!geocode || geocode.length < 1) {
        return null;
      }
      let postalCode = null;
      for (let addr of geocode) {
        postalCode = addr.address_components.find(
          (addrComponentItem) => addrComponentItem.types[0] === "postal_code"
        );
        if (postalCode && postalCode.short_name) {
          break;
        }
      }
      return postalCode.short_name;
    },

    callbackErrorGeolocate(error) {
      if (error.code === 1) {
        this.visibleMarker = false;
        this.zoom = 4;
      } else {
        console.log(error);
      }
    },
  },

  async beforeMount() {
    this.loading = true;
    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }
    try {
      this.busStop = BusStopFormStore.getters.busStop;
      this.busStopBackup = Object.assign({}, this.busStop);

      this.editMode = BusStopFormStore.getters.editMode;
      this.regions = await RegionService.getAllRegions();
      this.addressSearch = this.busStop.address;

      if (this.busStop.address != null) {
        this.$refs.gmapAutocomplete.$refs.input.value = this.busStop.address;

        this.marker.position.lat = parseFloat(
          this.convertCommaToPoint(this.busStop.latitude)
        );
        this.marker.position.lng = parseFloat(
          this.convertCommaToPoint(this.busStop.longitude)
        );
        this.currentLocation.lat = parseFloat(
          this.convertCommaToPoint(this.busStop.latitude)
        );
        this.currentLocation.lng = parseFloat(
          this.convertCommaToPoint(this.busStop.longitude)
        );
        this.visibleMarker = true;
        this.zoom = 16;
      }
    } catch (e) {
      this.alertType = "error";
      this.alertMessageType = "defaultError";
      this.showAlert = true;
      this.loading = false;
    } finally {
      this.loading = false;
    }
  },

  async mounted() {
    try {
      await this.geolocate();
    } catch (error) {
      this.callbackErrorGeolocate(error);
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;
}

.action-buttons {
  position: absolute;
  bottom: 2vh;
  width: calc(100% - 24px);
}

#myMap {
  height: 300px;
  width: auto;
}

.introInput {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border: $greyVale solid 0.5px;
  border-radius: 0.3rem;
  padding: 12px;
  height: 2.5rem;
  font-size: 1rem;
}
</style>
