<template>
  <v-app>
    <CustomAlert
      :dialog="showAlert"
      :type="alertType"
      :custom-message="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <NotificationCenter
      :showNotification="showNotification"
      @close-dialog="closeNotification"
    ></NotificationCenter>
    <v-app-bar class="px-0" elevation="0" app>
      <v-app-bar-nav-icon class="button-navbar mr-10" @click="toggleDrawer">
        <v-icon color="secondary"> mdi-menu </v-icon>
      </v-app-bar-nav-icon>

      <v-img
        class="mr-5 img_navbar"
        src="../assets/rotas-header-white-logo.svg"
        width="auto"
        max-height="24"
        max-width="140"
      ></v-img>

      <div class="text-right" style="width: 100%">
        <v-container>
          <div class="white--text">
            {{ employee.name }}
          </div>
          <div class="caption font-italic white--text">
            {{ employee.shift }}
          </div>
        </v-container>
      </div>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="menu-dropdown"
            v-bind="attrs"
            v-on="on"
            color="secondary"
          >
            mdi-menu-down
          </v-icon>
        </template>
        <v-list>
          <v-list-item to="/privacy-term">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-clipboard-outline</v-icon>
              Termo de Privacidade de Dados
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logoff">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-logout</v-icon>
              Sair
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid class="fill-height" style="background: #ebeaea">
        <v-row class="fill-height">
          <v-navigation-drawer
            width="auto"
            v-model="drawer"
            :mini-variant="miniVariant"
            update:mini-variant
            :permanent="screenWidth > 768"
            :expand-on-hover="screenWidth > 768 && drawer"
            fixed
          >
            <SideMenu></SideMenu>
          </v-navigation-drawer>
          <v-col>
            <router-view
              :class="[screenWidth > 768 ? 'ml-12' : 'ml-2']"
              style="width: 96.1%"
            >
            </router-view>

            <v-menu
              offset-y
              v-model="menuActive"
              :max-width="menuResponsive ? '480px' : '100%'"
              :min-width="menuResponsive ? '110px' : '100%'"
              ref="menu"
              :content-class="
                menuResponsive ? 'menu-help-principal' : 'menu-help-responsive'
              "
              :close-on-content-click="closeOnContentClick"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-6 mb-6"
                  :class="[
                    !menuResponsive ? 'custom-help-responsive' : 'custom-help',
                  ]"
                  :fab="!menuResponsive"
                  large
                  fixed
                  bottom
                  right
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-img
                    class="my-3"
                    :class="[!menuResponsive ? 'mr-0' : 'mr-2']"
                    src="../assets/logo.png"
                    width="auto"
                    max-height="24"
                    max-width="24"
                  ></v-img>
                  <b v-if="menuResponsive"> Ajuda </b>
                </v-btn>
              </template>
              <v-row
                v-if="!showRelease"
                no-gutters
                cols="9"
                class="d-flex justify-content-between"
              >
                <v-col class="px-0 py-0 ml-4 mt-4 mb-3 d-flex">
                  <v-img
                    class="mr-2"
                    src="../assets/logo.png"
                    width="auto"
                    max-height="24"
                    max-width="24"
                  ></v-img>
                  <b style="color: #393939"> Olá, posso ajudar? </b>
                </v-col>
                <v-col cols="3" align="end" class="px-0 py-0 mt-4 mr-4 mb-3">
                  <v-btn
                    class="px-0 py-0"
                    height="0"
                    min-width="0"
                    @click="closeMenuHelp"
                  >
                    <v-icon class="icon-costume">mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                v-if="showFaqs || showUserExperience"
                class="d-flex return-menu"
                @click="returnToMenu"
                no-gutters
              >
                <v-col class="ml-2 my-3 p-0">
                  <v-icon class="mr-2 icon-menu-return"
                    >mdi-chevron-left</v-icon
                  >
                  <b> Retornar aos principais tópicos </b>
                </v-col>
              </v-row>

              <v-row class="mx-0 my-0 px-0 py-0">
                <v-col class="mx-0 my-0 px-0 py-0">
                  <MenuHelp
                    v-if="
                      !(showFaqs || showUserExperience || showReleaseDialog)
                    "
                    v-on:openFaqs="openFaqsMenu"
                    v-on:openRelease="openReleaseMenu"
                    @openUserExperience="openUserExperienceMenu"
                  ></MenuHelp>

                  <Faqs v-if="showFaqs"></Faqs>

                  <GenericDialog
                    close
                    :visible="showReleaseDialog"
                    v-on:close-dialog="closeDialog"
                    width="554px"
                  >
                    <template v-slot:header>
                      <v-card-title
                        :class="[
                          menuResponsive
                            ? 'title-release'
                            : 'title-release-responsive',
                        ]"
                        class="px-0 py-0 mr-2"
                      >
                        {{
                          menuResponsive ? titleRelease : titleReleaseResponsive
                        }}
                      </v-card-title>
                    </template>
                    <template v-slot:body>
                      <div style="width: 530px;">
                        <v-responsive
                          class="overflow-y-auto"
                          color="primary"
                          max-height="calc(90vh - 200px)"
                        >
                          <ReleaseTabs></ReleaseTabs>
                          <div style="width: 25px"></div>
                        </v-responsive>
                      </div>
                    </template>
                  </GenericDialog>

                  <UserExperience
                    v-if="showUserExperience"
                    @show-message="showMessage"
                    @close-menu="closeMenuHelp"
                    @resize-menu="updateDimension"
                  ></UserExperience>
                </v-col>
              </v-row>
            </v-menu>
            <v-overlay :value="shouldShowOverlay"></v-overlay>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomAlert from "@/components/CustomAlert.vue";
import Faqs from "@/components/Faqs.vue";
import UserExperience from "../components/UserExperience.vue";
import MenuHelp from "../components/MenuHelp.vue";
import GenericDialog from "../components/GenericDialog.vue";
import NotificationCenter from "../components/NotificationCenter";
import ReleaseTabs from "./ReleaseTabs.vue";
import {EmployeeService} from "../services/employee"
export default {
  name: "App",

  components: {
    SideMenu,
    CustomAlert,
    Faqs,
    UserExperience,
    MenuHelp,
    GenericDialog,
    NotificationCenter,
    ReleaseTabs,
  },

  data: () => ({
    menuActive: false,
    menuResponsive: false,
    showFaqs: false,
    showUserExperience: false,
    showRelease: false,
    titleRelease: "Atualizações do sistema SUAvaga",
    titleReleaseResponsive: "Atualizações do SUAvaga",
    dontShow: true,
    drawer: false,
    closeOnContentClick: false,
    userName: localStorage.getItem("name")?.split(" ").slice(0, 2).join(" "),
    screenWidth: 1024,
    miniVariant: false,
    showReleaseDialog: false,
    showAlert: false,
    alertType: "",
    customMessage: "",
    showNotification: false,
    employee: {}
  }),

  methods: {
    async logoff() {
      try {
        localStorage.clear();
        this.$router.push("/");
      } catch (e) {
        console.log(e);
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    closeMenu() {
      this.allVariablesMenuFalse();
    },
    closeMenuHelp() {
      if (this.$refs.menu) {
        this.allVariablesMenuFalse();
        this.$refs.menu.isActive = false;
      }
    },
    myEventHandler(e) {
      this.screenWidth = e.currentTarget?.innerWidth;
    },
    closeDialog() {
      this.showReleaseDialog = false;
    },
    openDialog() {
      this.showReleaseDialog = true;
    },
    openNewTab() {
      window.open(
        "https://vale.service-now.com/sp?id=sc_cat_item&sys_id=eaf3df931ba120103a8185dde54bcb42&vc_par=1",
        "_blank",
        "noreferrer"
      );
    },
    updateDimension() {
      this.$nextTick(() => {
        if (this.$refs.menu) {
          this.$refs.menu.updateDimensions();
        }
      });
    },
    returnToMenu() {
      this.allVariablesMenuFalse();
      this.closeOnClick = false;
      this.updateDimension();
    },
    openFaqsMenu() {
      this.showFaqs = true;
      this.closeOnClick = true;
      this.updateDimension();
    },
    openUserExperienceMenu() {
      this.showUserExperience = true;
      this.closeOnClick = true;
      this.updateDimension();
    },
    openReleaseMenu() {
      this.showRelease = true;
      this.openDialog();
      this.closeMenuHelp();
    },
    allVariablesMenuFalse() {
      this.showFaqs = false;
      this.showUserExperience = false;
      this.showRelease = false;
    },
    showMessage(message, type) {
      this.showAlert = true;
      this.alertType = type;
      this.customMessage = message;
    },
    closeAlert() {
      this.showAlert = false;
    },
    closeNotification() {
      this.showNotification = false;
    },
    async updateEmployee(){
      let userInfoId = localStorage.getItem("userId");
      if (userInfoId) {
        try {
          this.employee = await EmployeeService.getEmployee(userInfoId);
          if(this.employee.shift == 'REMOTE') {
            this.employee.shift = 'REMOTO'
          }
          else if(this.employee.shift == 'ON_SITE') {
            this.employee.shift = 'PRESENCIAL'
          }else{
            this.employee.shift = ''
          }
        }
        catch (e) {
          if (e.status !== 404) 
            console.log(e);
        }
      } 
    },
  },

  computed: {
    shouldShowOverlay() {
      return this.menuActive && !this.menuResponsive;
    },
  },
  watch: {
    screenWidth: function (newValue) {
      if (newValue > 768 && this.drawer) {
        this.miniVariant = true;
      } else {
        this.miniVariant = false;
      }
    },
    drawer: function (newValue) {
      if (this.screenWidth > 768) {
        this.miniVariant = newValue;
        this.menuResponsive = newValue;
      } else {
        this.miniVariant = false;
        this.menuResponsive = false;
      }
    },
    $route(to) {
      if (
        to.name === "StartPage" &&
        localStorage.getItem("showNotification") === "true"
      ) {
        this.showNotification = true;
        localStorage.removeItem("showNotification");
      }
    },
    menuActive: function () {
      this.allVariablesMenuFalse();
    },
  },
  mounted(){
    this.updateEmployee()
  },
  async created() {
    this.screenWidth = window.innerWidth;
    this.miniVariant = this.screenWidth > 768;
    this.menuResponsive = this.miniVariant;

    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
::v-deep .v-app-bar {
  background-color: $greenValeDark !important;
}
.box {
  box-shadow: none;
}
.button-navbar {
  position: 29px;
}
.button-navbar:hover {
  background-color: #007d73;
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

::v-deep .v-navigation-drawer__content {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

::v-deep .v-navigation-drawer--fixed {
  z-index: 1;
}

@media (min-width: 1904px) {
  ::v-deep .container {
    max-width: 2560px;
  }
}

.custom-help {
  background-color: #ffffff !important;
  color: #393939;
  border-width: thin;
  border-style: solid;
  border-color: #b7dddb;
  text-transform: none;
  border-radius: 8px;
}

.custom-help-responsive {
  background-color: #ffffff !important;
  color: #393939;
  border-width: thin;
  border-style: solid;
  border-color: #b7dddb;
  text-transform: none;
  width: 48px;
  height: 48px;
}

.menu-help-responsive {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-width: thin;
  border-style: solid;
  border-color: #b7dddb;
  background-color: white;
  max-height: 50vh;
  overflow-y: auto;
  position: fixed !important;
  bottom: 0% !important;
  top: unset !important;
}

.menu-help-principal {
  border-radius: 8px;
  border-width: thin;
  border-style: solid;
  border-color: #b7dddb;
  background-color: white;
}

::v-deep .v-btn:not(.v-btn--round).v-size--large {
  padding: 0 16px;
}

.btn-content {
  display: flex;
  flex-direction: column;
  color: #008f83;
  font-size: 12px;
}

.btn-home {
  width: 154px !important;
  height: 126px !important;
  background-color: #d6ebea !important;
  text-transform: none;
  font-weight: 400;

  @media (max-width: "1365px") {
    width: 100% !important;
    height: 72px !important;
    margin-bottom: 0px;
    margin-right: 12px;
  }
}

.icon-costume {
  font-size: 20px;
  color: #6f6f6f;
}

.icon-menu-return {
  font-size: 16px;
  color: #a8a8a8;
}

.return-menu {
  background: #f4f4f4;
  color: #a8a8a8;
  font-size: 12px;
  cursor: pointer;
}

.title-release {
  color: #393939;
  font-size: 24px;
}

.title-release-responsive {
  color: #393939;
  font-weight: bold;
  font-size: 20px;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f6f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

::v-deep .v-responsive__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
